import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import tw, { styled } from 'twin.macro'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
//
import Layout from '~components/layout'
import PageLayout from '~storybook/layout'
import SEO from '~components/seo'
import { ParagraphComponents } from '~utils/components'
import ImageProxy from '~storybook/image'
import SocialShare from '~storybook/social-share'
import { Label, Tag } from '~storybook/blocks/typography/index'
import Icons from '~storybook/icons/index'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const ContentWrapper = styled.div`
  p {
    ${tw`text-lg`}
  }
`

const Event = ({ data, pageContext, location }) => {
  const { node, group } = data.drupal
  const { nid, language, menus, metatags } = pageContext || {}
  const {
    fieldNewsDate,
    fieldParagraphs,
    fieldSidebar,
    fieldEntityImage,
    fieldNewsSource,
    fieldEventLocation,
    fieldTypeLabel,
    fieldNewsSummary
  } = node || {}
  const langCode = language?.toLowerCase()
  const langMenus = menus[langCode] || {}
  const {
    fieldSidebar: fieldSiteSidebar,
    fieldSocialShareButtons,
    fieldAnnouncement,
    fieldGdprTitle,
    fieldGdprDescription,
    fieldGdprCookies = [],
    fieldMetaSiteImage
  } = group || {}

  const sidebarParagraphs = (fieldSidebar?.length && fieldSidebar) || fieldSiteSidebar || null
  const sidebar = sidebarParagraphs ? <ParagraphComponents paragraphs={sidebarParagraphs} language={langCode} /> : null

  const newsDate = new Date(fieldNewsDate?.value)

  const sources = fieldNewsSource?.map((source) => source?.entity) || []

  const intl = useIntl()
  const dateStr = newsDate && `${formatTZ(newsDate, 'd LLLL yyyy | HH:mm', { locale: localesObj?.[intl?.locale] })}`

  return (
    <Layout
      language={langCode}
      menus={langMenus}
      announcement={fieldAnnouncement}
      cookiesInfo={{ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }}
    >
      <SEO
        key={`news-${nid}-${langCode}`}
        title={node.title}
        metatags={metatags}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
        lang={langCode}
        nid={nid}
        gid={group.id}
        langSlugs={pageContext.langSlugs}
      />
      <PageLayout sidebar={sidebar}>
        <div className="flex flex-row">
          <div>
            <Tag css={tw`text-c-news-template-text`}>{fieldTypeLabel || intl.formatMessage({ id: 'news.event' })}</Tag>
          </div>

          {dateStr && (
            <div className="ml-2">
              <Label css={tw`text-c-news-template-label`}>{dateStr}</Label>
            </div>
          )}

          {fieldEventLocation && (
            <div className="ml-4 flex items-center">
              <div>
                <Icons.LocationPin />
              </div>
              <Label css={tw`text-c-news-template-label`}>{fieldEventLocation}</Label>
            </div>
          )}
        </div>

        <h1 className="my-4">{node?.title}</h1>

        <div className="my-4 py-4">
          <SocialShare enabled={fieldSocialShareButtons} url={location?.href} />
        </div>

        {fieldEntityImage?.entity?.thumbnail?.url ? (
          <section className="bg-white-500 w-full mb-8 relative">
            <ImageProxy
              type="fluid"
              url={fieldEntityImage?.entity?.thumbnail?.url}
              params={{ mw: 1584, mh: 892, g: 'sm', r: 'fill' }}
            />
          </section>
        ) : null}

        <ContentWrapper>
          {fieldParagraphs?.length ? (
            <ParagraphComponents paragraphs={fieldParagraphs} language={langCode} />
          ) : (
            fieldNewsSummary
          )}
        </ContentWrapper>

        {sources?.map((source) => (
          <p key={`source-${source.entityId}`} css={tw`text-c-news-template-text font-medium text-sm`}>
            ({source.entityLabel})
          </p>
        ))}
      </PageLayout>
    </Layout>
  )
}

export default Event

export const eventQuery = graphql`
  query($gid: String!, $nid: String!, $language: Drupal_LanguageId!) {
    drupal {
      node: nodeById(id: $nid, language: $language) {
        title
        path {
          alias
        }
        entityId
        langcode {
          value
        }
        ... on Drupal_NodeNews {
          fieldNewsDate {
            value
          }
          fieldTypeLabel
          fieldNewsSource {
            entity {
              entityId
              entityLabel
            }
          }
          fieldEventLocation
          fieldEntityImage {
            entity {
              ...FragmentImage
            }
          }
          fieldNewsSummary
          fieldParagraphs {
            ...FragmentNewsFieldParagraphs
          }
          fieldSidebar {
            ...FragmentNewsFieldSidebarParagraphs
          }
        }
      }
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
